import { ParticipantEvent, Room, RoomEvent } from 'livekit-client';

const EgressHelper = {
  /**
   * RoomComposite will pass URL to your livekit's server instance.
   * @returns
   */
  getLiveKitURL(): string {
    const url = getURLParam('url');
    if (!url) {
      throw new Error('url is not found in query string');
    }
    return url;
  },

  /**
   *
   * @returns access token to pass to `Room.connect`
   */
  getAccessToken(): string {
    const token = getURLParam('token');
    if (!token) {
      throw new Error('token is not found in query string');
    }
    return token;
  },

  /**
   * Call when successfully connected to the room
   * @param room
   */
  setRoom(room: Room) {
    if (currentRoom) {
      currentRoom.off(RoomEvent.ParticipantDisconnected, onParticipantDisconnected);
      currentRoom.off(RoomEvent.Disconnected, EgressHelper.endRecording);
      currentRoom.off(RoomEvent.ParticipantConnected, onParticipantConnected);
      currentRoom.off(RoomEvent.TrackPublished, onTrackPublished);
      currentRoom.off(RoomEvent.TrackUnpublished, onTrackUnpublished);
    }

    currentRoom = room;
    currentRoom.localParticipant.on(ParticipantEvent.ParticipantMetadataChanged, onMetadataChanged);
    currentRoom.on(RoomEvent.ParticipantDisconnected, onParticipantDisconnected);
    currentRoom.on(RoomEvent.Disconnected, EgressHelper.endRecording);
    currentRoom.on(RoomEvent.ParticipantConnected, onParticipantConnected);
    currentRoom.on(RoomEvent.TrackPublished, onTrackPublished);
    currentRoom.on(RoomEvent.TrackUnpublished, onTrackUnpublished);
    onMetadataChanged();
  },

  /**
   * Starts recording the room that's passed in
   */
  startRecording() {
    console.log('START_RECORDING');
  },

  /**
   * Finishes recording the room, by default, it'll end automatically finish
   * when all other participants have left the room.
   */
  endRecording() {
    currentRoom = undefined;
    console.log('END_RECORDING');
  },

  /**
   * Registers a callback to listen to layout changes.
   * @param f
   */
  onLayoutChanged(f: (layout: string) => void) {
    layoutChangedCallback = f;
  },
};

let currentRoom: Room | undefined;
let participantDisconnectTimeout: NodeJS.Timer | undefined;
let trackUnpublishTimeout: NodeJS.Timer | undefined;

let layoutChangedCallback: (layout: string) => void | undefined;
let state: TemplateState = {
  layout: '',
};

interface TemplateState {
  layout: string;
}

function onMetadataChanged() {
  // for recorder, metadata is a JSON object containing layout
  const metadata = currentRoom?.localParticipant.metadata;
  if (metadata) {
    const newState: TemplateState = JSON.parse(metadata);
    if (newState && newState.layout !== state.layout) {
      state = newState;
      layoutChangedCallback(state.layout);
    }
  }
}

function onParticipantDisconnected() {
  if (currentRoom) {
    participantDisconnectTimeout = setTimeout(() => {
      endRecordingIfNoPublishers();
    }, 5000);
  }
}

function onParticipantConnected() {
  if (currentRoom) {
    clearTimeout(participantDisconnectTimeout);
  }
}

function onTrackPublished() {
  if (currentRoom) {
    clearTimeout(trackUnpublishTimeout);
    clearTimeout(participantDisconnectTimeout);
  }
}

function onTrackUnpublished() {
  if (currentRoom) {
    trackUnpublishTimeout = setTimeout(() => {
      endRecordingIfNoTracks();
    }, 10000);
  }
}

function endRecordingIfNoPublishers() {
  if (currentRoom) {
    const publishers = Array.from(currentRoom.remoteParticipants.values()).filter((p) => {
      return p.permissions?.canPublish === true;
    });
    if (publishers.length === 0) {
      EgressHelper.endRecording();
    }
  }
}

function endRecordingIfNoTracks() {
  if (currentRoom) {
    const hasActivePublishers = Array.from(currentRoom.remoteParticipants.values())
      .filter((p) => {
        return p.permissions?.canPublish;
      })
      .map((p) => {
        return Array.from(p.trackPublications.values()).filter((t) => !t.isMuted).length > 0;
      })
      .reduce((acc, val) => acc || val, false);
    if (!hasActivePublishers) {
      EgressHelper.endRecording();
    }
  }
}

function getURLParam(name: string): string | null {
  const query = new URLSearchParams(window.location.search);
  return query.get(name);
}

export default EgressHelper;
