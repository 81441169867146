import {
  LiveKitRoom,
  RoomAudioRenderer,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import { ConnectionState, RoomEvent, Track } from 'livekit-client';
import { ReactNode, useEffect, useState } from "react";
import EgressHelper from './helper';
import { useVisualStableUpdate, VideoTrack } from '@livekit/components-react';
import { TrackReference } from '@livekit/components-core';

interface LayoutProps {
  tracks: TrackReference[];
}

interface RoomPageProps {
  url: string;
  token: string;
}

const View: React.FC<LayoutProps> = ({ tracks }) => {
  const sortedReferences = useVisualStableUpdate(tracks, 1);
  if (sortedReferences.length === 0) {
    return null;
  }
  const trackReference = sortedReferences[0]
  const participant = trackReference.participant;
  const publication = trackReference.publication;
  if (!publication) {
    // This should never happen as this view is only passed valid publications.
    return null;
  }
  const source = trackReference.source;

  return <VideoTrack trackRef={{ participant, publication, source }} />;
};

export default function RoomPage({ url, token }: RoomPageProps) {
  const [error, setError] = useState<Error>();
  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  return (
    <LiveKitRoom serverUrl={url} token={token} onError={setError}>
      {error ? <div className="error">{error.message}</div> : <CompositeTemplate />}
    </LiveKitRoom>
  );
}
function CompositeTemplate() {
  const room = useRoomContext();

  useEffect(() => {
    if (room) {
      EgressHelper.setRoom(room);
      // start recording when there's already a track published
      let hasTrack = false;
      for (const p of Array.from(room.remoteParticipants.values())) {
        if (p.getTrackPublications().length > 0) {
          hasTrack = true;
          break;
        }
      }

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () => EgressHelper.startRecording());
      }
    }
  }, [room]);

  const allTracks = useTracks(
    [Track.Source.Camera, Track.Source.ScreenShare, Track.Source.Unknown],
    {
      onlySubscribed: true,
    },
  );
  const filteredTracks = allTracks.filter(
    (tr) =>
      tr.publication.kind === Track.Kind.Video &&
      tr.participant.identity !== room.localParticipant.identity,
  );

  // determine layout to use
  let main: ReactNode = null;
  if (room.state !== ConnectionState.Disconnected) {
    main = <View tracks={filteredTracks} />;
  }

  return (
    <div className={'roomContainer dark'}>
      {main}
      <RoomAudioRenderer />
    </div>
  );
}
